<template>
  <v-form @submit.prevent="login(username, password)">
    <v-container>
      <v-col md="4" offset-md="4">
        <h1 style="margin: -12px -12px 0.5rem;">Login</h1>
        <v-row>
          <v-text-field label="Username" v-model="username" autocomplete="username" required>
          </v-text-field>
        </v-row>
        <v-row>
          <v-text-field label="Password" type="password" v-model="password" autocomplete="current-password" required>
          </v-text-field>
        </v-row>
        <v-row class="justify-center">
          <v-btn color="success" type="submit">
            Login!
          </v-btn>
        </v-row>
      </v-col>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";

export default {
  name: "Login",
  data: function (){
    return {
      "username": "",
      "password": ""
    }
  },
  methods: {
    login: function(username, password){
      axios.get(this.$baseURL + "/login/", {
        auth: {
          username: username,
          password: password
        }
      }).then(response => {
        if (response.status === 200){
          this.$store.dispatch('loginUser', {
            "user": username,
            "token": response.data["token_string"],
            "time_created": response.data["time_created"]
          })
          this.$router.push({name: 'Home'})
        }
      })
      return null
    }
  }
}
</script>

<style scoped>

</style>